<template>
  <div>
    <div class="plTitle">评价订单</div>
    <div class="plTxt dis-cent">
      <div class="txt">订单号：{{ info.ordernum }}</div>
      <div class="txt">{{ info.create_time }}</div>
    </div>
    <div class="content">
      <div class="cont" v-for="(item, index) in carlist">
        <div class="cpBox">
          <div class="img">
            <img :src="$imgUrl + item.part.resources[0].path_name" alt="" />
          </div>
          <div class="name">{{ item.productname }}</div>
        </div>
        <div class="contBox">
          <div class="box">
            <div class="name">商品评分</div>
            <div class="starBox">
              <div
                @mouseenter="mouseover(chi, index)"
                @mouseleave="mouseout(chi, index)"
                @click="cpClick(chi, index)"
                class="iconfont"
                :class="
                  chi >= item.on ? 'icon-collection' : 'icon-collection_fill on'
                "
                v-for="(chr, chi) in item.setar"
              ></div>
              <div class="txt">{{ item.on }}分</div>
            </div>
          </div>
          <div class="box">
            <div class="name" style="margin-top: 8px">评价晒单</div>
            <div class="funBox">
              <div class="textVal">
                <textarea
                  name=""
                  id=""
                  placeholder="快来分享体验心得~"
                  v-model="item.xinde"
                ></textarea>
              </div>
              <div class="upImg">
                <div class="imgs">
                  <div
                    class="img"
                    @click="uploadFile(index)"
                    v-if="item.imgList.length < 9"
                  >
                    <div class="imgBox iconfont icon-camera_fill"></div>
                  </div>
                  <div class="img" v-for="(chr, chi) in item.imgList">
                    <img :src="$imgUrl + chr.path_name" />
                    <div
                      class="iconfont icon-close close"
                      @click="imgDelClick(chi, index)"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="saveCont dis-cent">
      <div class="saveBtn dis-cent" @click="plClick">发表评价</div>
    </div>
    <input
      type="file"
      ref="fileInput"
      @change="onFileChange"
      style="display: none"
    />
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      list: [
        {
          name: "1",
          qd: 0,
          on: 0,
          setar: 5,
          imgList: [],
        },
        {
          name: "2",
          qd: 0,
          on: 0,
          setar: 5,
          imgList: [],
        },
      ],
      imgI: 0,
      info: {},
      carlist: [],
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.get();
  },
  methods: {
    get() {
      this.$get("/web/order_get", {
        id: this.id,
      }).then((res) => {
        console.log(res);
        for (let i = 0; i < res.carlist.length; i++) {
          const e = res.carlist[i];
          e.qd = 0;
          e.on = 0;
          e.setar = 5;
          e.imgList = [];
        }
        this.$forceUpdate();
        this.carlist = res.carlist;
        this.info = res.data;
      });
    },
    imgDelClick(i, gaoI) {
      this.carlist[gaoI].imgList.splice(i, 1);
    },
    async uploadFile(i) {
      this.imgI = i;
      this.$refs.fileInput.click();
    },
    onFileChange(e) {
      var formData = new FormData();
      console.log(e.target.files);
      formData.append("file", e.target.files[0]);
      formData.append("where", "product");
      this.$uploadfile("/common/fileupload", formData).then((res) => {
        console.log(res);
        if (res.data.status) {
          this.carlist[this.imgI].imgList.push(res.data.data);
        } else {
          this.$message.error("网络繁忙,请稍后再试~");
        }
      });
    },
    mouseover(chi, i) {
      this.carlist[i].on = chi + 1;
    },
    mouseout(chi, i) {
      if (!this.carlist[i].qd) {
        this.carlist[i].on = 0;
      } else {
        this.carlist[i].on = this.carlist[i].qd;
      }
    },
    cpClick(chi, i) {
      this.carlist[i].qd = chi + 1;
      this.carlist[i].on = chi + 1;
    },
    plClick() {
      let data = [];
      for (let i = 0; i < this.carlist.length; i++) {
        const e = this.carlist[i];
        let imgId = [];
        for (let j = 0; j < e.imgList.length; j++) {
          const ej = e.imgList[j];
          imgId.push(ej.id);
        }
        data.push({
          content: e.xinde,
          resource: imgId.join(","),
          part_id: Number(e.part_id),
          order_id: Number(this.id),
          car_id: e.id,
          score: e.on,
        });
      }
      for (let i = 0; i < data.length; i++) {
        const e = data[i];
        if (!e.content) {
          this.$message.error("请填写评价内容");
          return;
        }
        if (!e.score) {
          this.$message.error("请对产品打分");
          return;
        }
      }
      data = JSON.stringify(data);
      this.$post("/web/evaluation_save", {
        data: data,
      }).then((res) => {
        console.log(res);
        this.visible = false;
        if (res.data.status) {
          this.$message.success(res.data.msg);
          this.urlTz();
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    urlTz() {
      let url = "/chuantian/orderCont?id=" + this.id;
      const newWindow = window.open(url, "_blank");
      if (newWindow) {
        // 尝试关闭当前窗口
        window.close();
        // 如果当前窗口未关闭，则在新窗口打开目标页面并关闭当前页面
        if (!window.closed) {
          newWindow.focus();
        }
      } else {
        // 如果打开新窗口失败（如被浏览器拦截），则在当前窗口跳转
        window.location.href = url;
      }
    },
  },
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.plTitle {
  font-weight: 900;
  font-size: 16px;
  text-align: center;
  margin-top: 25px;
}

.plTxt {
  margin-top: 5px;
  .txt {
    margin-right: 20px;
  }
  .txt:last-child {
    margin-right: 0;
  }
}

.content {
  width: 1440px;
  margin: auto;
  background-color: white;
  border-radius: 5px;
  margin-top: 30px;
  .cont {
    border-bottom: 1px solid #ebebeb;
    display: flex;
    .cpBox {
      width: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-right: 1px solid #ebebeb;
      padding: 10px 0;
      .img {
        width: 150px;
      }
      .name {
        margin-top: 10px;
        color: #666;
      }
    }
    .contBox {
      width: calc(100% - 40px);
      box-sizing: border-box;
      padding: 20px 30px;
      .box {
        display: flex;
        margin-bottom: 20px;
        .name {
          width: 150px;
          height: 30px;
          line-height: 30px;
        }
        .starBox {
          width: calc(100% - 150px);
          display: flex;
          align-items: center;
          .iconfont {
            font-size: 20px;
            color: #c9c9c9;
            cursor: pointer;
          }
          .on {
            color: #e23;
          }
          .txt {
            margin-left: 10px;
            margin-top: -2px;
            color: #9c9c9c;
          }
        }
        .funBox {
          width: calc(100% - 150px);
          .textVal {
            width: 100%;
            border-radius: 5px;
            border: 1px solid #ebebeb;
            overflow: hidden;
            box-sizing: border-box;
            textarea {
              border: none;
              width: 100%;
              height: 150px;
              padding: 10px 15px;
            }
          }
          .upImg {
            margin-top: 10px;

            .text {
              margin-left: 20px;
              font-size: 12px;
            }

            .txt {
              margin-left: 5px;
              font-size: 12px;
              color: #999;
            }

            .imgs {
              display: flex;
              margin-top: 15px;
              flex-wrap: wrap;

              .img {
                width: calc(10% - 9px);
                margin-right: 10px;
                padding-top: calc(10% - 9px);
                position: relative;
                overflow: unset;
                .imgBox {
                  width: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 100%;
                  border-radius: 5px;
                  box-shadow: 0 0 4px 2px #ebebeb;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: #9c9c9c;
                  font-size: 26px;
                  cursor: pointer;
                }
                img {
                  position: absolute;
                  top: 0;
                  left: 0;
                  border-radius: 5px;
                }

                .close {
                  position: absolute;
                  top: -3px;
                  right: -3px;
                  background-color: #b3b3b3;
                  border-radius: 50%;
                  width: 15px;
                  height: 15px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 10px;
                  color: #fff;
                  cursor: pointer;
                }
              }

              .img:nth-child(5n) {
                margin-right: 0;
              }
            }
          }
        }
      }
      .box:last-child {
        margin-bottom: 10px;
      }
    }
  }
  .cont:last-child {
    border-bottom: none;
  }
}

.saveCont {
  width: 1440px;
  margin: auto;
  background-color: white;
  border-radius: 5px;
  height: 120px;
  margin-top: 25px;
  .saveBtn {
    background-color: #e23;
    color: #fff;
    width: 250px;
    height: 55px;
    border-radius: 5px;
    cursor: pointer;
  }
}
</style>
